@import 'two-app-ui/build/esm/src/scss/theme/_variables';

.custom-tooltip {
  width: 500px;
  max-width: 100%;
}

.p-progress {
  background: $primaryColor;
  color: $primaryTextColor;
  font-size: 0.75rem;
  font-weight: 700;
  padding: 0.25rem 0.4rem;
}

.p-progress-blue {
  background-color: map-get($colors, 'blue');
  color: #fff;
}

.p-progress-red {
  background-color: map-get($colors, 'red');
  color: #fff;
}

.p-progress-green {
  background-color: map-get($colors, 'green');
  color: #fff;
}
