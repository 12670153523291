@import 'two-app-ui/build/esm/src/scss/theme/_variables';
#add_edit_proof_dialog {
  .orders-panel{
    .p-card-body {
      padding: 0.5rem 1rem;
      .p-card-content {
        padding: 0.3rem 0;
      }
    }
  }
  .photos-panel {
    .photos-upload-button {
      .p-button-label {
        display: none;
      }
    }
  }
  .p-panel {
    .p-panel-header {
      background-color: $shade600;
      color: $shade000;
    }
    .p-panel-content {
      background-color: $shade300;
      padding: 1rem;
    }
  }
}
.remove-confirmation-dialog {
  .p-dialog-header {
    .p-dialog-header-icons {
      display: none;
    }
    .p-dialog-content, .p-dialog-footer {
      padding: 1.2rem;
    }
  }
}
.galleria-download-button {
  position: fixed;
  right: 0;
  bottom: 0;
  margin: 0.5rem;
  font-size: 2rem;
  padding: 1rem !important;
  width: 4rem !important;
  height: 4rem !important;
  border-radius: 50%;
  &:hover {
    background: rgba(255, 255, 255, 0.1) !important;
    color: #e9ebed;
  }
}
