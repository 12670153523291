@import 'two-app-ui/build/esm/src/scss/theme/_variables';

#schedule-box {
  border: 0;
  .p-fieldset-content {
    padding: 0;
  }
}

.schedule-calendar {
  .table-menu-button-container {
    height: 100%;

    .menu-button {
      background-color: $shade600;
      border-color: $shade600;
    }

    .menu-button:hover {
      background-color: $shade600;
      border-color: $shade600;
    }
  }
  .fc-timeline-lane-misc{
    height: 100%;
    position: absolute;
    width:100%;

    .cell-menu-container{
      height: 100%;
      position: relative;
      .table-menu-container{
        height: 100%;
        .table-menu-button-container{
          height: initial;
          bottom: 0;
          top: auto;
          margin-bottom: 2px;
          margin-right: 2px;
          z-index: 5;
        }
      }
    }
  }
}

.fc .fc-timeline-slots{
  z-index: initial !important; //rewrite default z-index for correct menu functionality
}

#calendar {
  .p-splitter .p-splitter-gutter .p-splitter-gutter-handle {
    background: $shade500;
  }

  .schedule-calendar {
    display: flex;
    flex-direction: column;
  }
  .fc-toolbar-chunk {
    display: flex;
  }

  .p-splitter {
    border: none !important;
    background: none !important;
  }

  .fc-direction-ltr {
    height: 100% !important;
  }

  .fc-event,
  .fc-event {
    border: 1px solid $primaryColor;
    background-color: $primaryColor;
    color: white;
    &:not(:hover) {
      .fa-external-link {
        display: none;
      }
    }
  }
  .fc-day {
    .fc-daygrid-day-number {
      width: 100%;
      .table-menu-container {
        > div {
          margin-left: auto;
        }
        .table-menu-button-container {
          left: 0;
        }
      }
    }
    &:not(:hover) {
      .table-menu-button-container {
        display: none !important;
      }
    }

    //always turn of the menu button on day and week body column
    .fc-timegrid-col-misc .table-menu-button-container {
      display: none !important;
    }
  }

  .fc .fc-highlight {
    background-color: $surface200;
  }

  .fc .fc-daygrid-day.fc-day-today {
    background-color: $surface100;
  }
  .fc .fc-timegrid-col.fc-day-today {
    background-color: $surface100;
  }

  .fc .fc-button-primary:disabled {
    background-color: $shade700;
    border-color: $shade700;
  }

  .fc-view-harness {
    height: 100% !important;
  }

  .p-datatable-wrapper {
    max-height: 100% !important;
  }

  .fc-scrollgrid-section-header {
    color: $shade600;
    background: $shade300;
  }

  .fc-scrollgrid-sync-table {
    border-bottom-style: none !important;
  }

  .fc-timeline-slot-cushion{
    width: 90% !important;

    .table-menu-container{
      justify-content: center !important;
    }
  }


}

.splitter-panel-calendar > div{
  width: 100%;
}

.drag-el {
  cursor: grab;
}

.run-stage-done {
  background-color: #29c76f !important;

  .fc-event-main{
    color: #fff !important;
  }
}

.run-stage-in-progress {
  background-color: #ff9f42 !important;

  .fc-event-main{
    color: #000 !important;
  }
}

.run-stage-scheduled {
  background-color: #5486ea !important;

  .fc-event-main{
    color: #fff !important;
  }
}

.run-stage-draft {
  background-color: #c724b1 !important;

  .fc-event-main{
    color: #fff !important;
  }
}

.task-reminder {
 // border-color: #FFFFE0 !important;
  background-color: #FFFFE0 !important;

  .fc-event-main{
    color: black !important;
  }
}
