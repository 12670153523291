//stage badge style and colors
.stage-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
  &.run-stage-draft {
    background-color: #e08fff;
    color: #fff;
  }
  &.stage-in-container,
  &.stage-at-warehouse,
  &.stage-at-factory,
  &.stage-inbound,
  &.run-stage-scheduled,
  &.stop-stage-planned,
  &.container-stage-inbound {
    background-color: #5486ea;
    color: #fff;
  }
  &.stage-with-3rd-party,
  &.stage-on-board,
  &.runstage-in-progress,
  &.stop-stage-in-progress,
  &.container-stage-in-progress {
    background-color: #ff9f42;
    color: #000;
  }
  &.stage-delivered,
  &.stage-picked-up,
  &.run-stage-done,
  &.stop-stage-done,
  &.stage-ready-for-dispatch,
  &.container-stage-done {
    background-color: #29c76f;
    color: #fff;
  }
  &.stage-mixed {
    background-color: #ff4150;
    color: #fff;
  }
}
.stage-badge-filter {
  padding: 0.1px 0.1px;
  text-transform: none !important;
  font-weight: inherit !important;
  font-size: inherit !important;
  letter-spacing: inherit !important;
  height: 100%;
}

.stage-filter {
  .p-multiselect-label {
    height: 100%;
  }
}

.p-datatable-row-expansion {
  > td:first-child {
    padding: 0px 10px 0px 50px !important;
  }
}

.p-datatable .p-datatable-row-expansion {
  //this is for descendants table's tables
  th, .p-paginator {
    //header and footer color of table's child table
    background-color: #dbe5ed !important;
  }
  .p-datatable-row-expansion{
    th, .p-paginator{
      //header and footer color of table's grandchild table
      background-color: #d9e7df !important;
    }
  }
}

.no-filter-selection {
  width: 70px !important;
}

.no-filter-selection {
  &.selection-check {
    > .p-column-header-content {
      flex-direction: row-reverse;
    }
  }
}
