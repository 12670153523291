#location_list_page_container {
  .no-selection {
    .p-checkbox .p-component {
      display: none !important;
    }
  }
}

#order_list_page_container_location_order {
  .p-selection-column,
  .selection-check {
    .p-checkbox .p-component {
      display: block;
    }
  }
}
