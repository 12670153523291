#run-box {
  width: 100%;
  .p-fieldset-content {
    padding: 0;
    display: flex;
    width: 100%;
  }

  .p-toggleable-content {
    display: flex;
    width: 100%;
  }
}
